import { ModelApiKey } from '../types/models'
import { LocaleCode } from '../types/locales'

export const paths = {
  [LocaleCode.DE_DE]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'discover' },
    [ModelApiKey.DiscoverCategory]: { path: 'discover' },
    [ModelApiKey.HelpArticle]: { path: 'hilfe' },
    [ModelApiKey.HelpTopic]: { path: 'hilfe' },
    [ModelApiKey.CryptoAsset]: { path: 'globaler-kryptomarkt' },
    glossary: { path: 'glossar' },
    search: { path: 'hilfe-suche' },
    tm: { path: 'cryptonow-time-machine' },
    compare: { path: 'vergleiche-krypto-projekte' }
  },
  [LocaleCode.EN_DE]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'discover' },
    [ModelApiKey.DiscoverCategory]: { path: 'discover' },
    [ModelApiKey.HelpArticle]: { path: 'help' },
    [ModelApiKey.HelpTopic]: { path: 'help' },
    [ModelApiKey.CryptoAsset]: { path: 'global-crypto-market' },
    glossary: { path: 'glossar' },
    search: { path: 'help-search' },
    tm: { path: 'cryptonow-time-machine' },
    compare: { path: 'compare-crypto-assets' }
  },
  [LocaleCode.DE_CH]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'discover' },
    [ModelApiKey.DiscoverCategory]: { path: 'discover' },
    [ModelApiKey.HelpArticle]: { path: 'hilfe' },
    [ModelApiKey.HelpTopic]: { path: 'hilfe' },
    [ModelApiKey.CryptoAsset]: { path: 'globaler-kryptomarkt' },
    glossary: { path: 'glossar' },
    search: { path: 'hilfe-suche' },
    tm: { path: 'cryptonow-time-machine' },
    compare: { path: 'vergleiche-krypto-projekte' }
  },
  [LocaleCode.EN_CH]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'discover' },
    [ModelApiKey.DiscoverCategory]: { path: 'discover' },
    [ModelApiKey.HelpArticle]: { path: 'help' },
    [ModelApiKey.HelpTopic]: { path: 'help' },
    [ModelApiKey.CryptoAsset]: { path: 'global-crypto-market' },
    glossary: { path: 'glossar' },
    search: { path: 'help-search' },
    tm: { path: 'cryptonow-time-machine' },
    compare: { path: 'compare-crypto-assets' }
  },
  [LocaleCode.FR_CH]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'decouvrir' },
    [ModelApiKey.DiscoverCategory]: { path: 'decouvrir' },
    [ModelApiKey.HelpArticle]: { path: 'assistance' },
    [ModelApiKey.HelpTopic]: { path: 'assistance' },
    [ModelApiKey.CryptoAsset]: { path: 'marche-mondial-des-cryptomonnaies' },
    glossary: { path: 'glossar' },
    search: { path: 'aide-recherche' },
    tm: { path: 'cryptonow-time-machine' },
    compare: { path: 'outil-de-comparaison-des-cryptos' }
  },
  [LocaleCode.IT_CH]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'scoprire' },
    [ModelApiKey.DiscoverCategory]: { path: 'scoprire' },
    [ModelApiKey.HelpArticle]: { path: 'supporto' },
    [ModelApiKey.HelpTopic]: { path: 'supporto' },
    [ModelApiKey.CryptoAsset]: { path: 'mercato-globali-delle-criptovalute' },
    glossary: { path: 'glossar' },
    search: { path: 'aiuto-ricerca' },
    tm: { path: 'cryptonow-time-machine' },
    compare: { path: 'tool-di-confronto-per-criptovalute' }
  },
  [LocaleCode.DE_AT]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'discover' },
    [ModelApiKey.DiscoverCategory]: { path: 'discover' },
    [ModelApiKey.HelpArticle]: { path: 'hilfe' },
    [ModelApiKey.HelpTopic]: { path: 'hilfe' },
    [ModelApiKey.CryptoAsset]: { path: 'globaler-kryptomarkt' },
    glossary: { path: 'glossar' },
    search: { path: 'hilfe-suche' },
    tm: { path: 'cryptonow-time-machine' },
    compare: { path: 'vergleiche-krypto-projekte' }
  },
  [LocaleCode.EN_AT]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'discover' },
    [ModelApiKey.DiscoverCategory]: { path: 'discover' },
    [ModelApiKey.HelpArticle]: { path: 'help' },
    [ModelApiKey.HelpTopic]: { path: 'help' },
    [ModelApiKey.CryptoAsset]: { path: 'global-crypto-market' },
    glossary: { path: 'glossar' },
    search: { path: 'help-search' },
    tm: { path: 'cryptonow-time-machine' },
    compare: { path: 'compare-crypto-assets' }
  }
}
